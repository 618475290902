import React from "react";

const block404 = {
  id: "block404",
  title: "404 Hero",
  ctaText: "View Our Deisgn System",
  classNames: null,
  useGutters: null,
  backgroundColor: "green",
  ctaColor: "orange",
  ctaUrl: null,
  ctaContent: {
    id: "home",
    slug: "",
    sys: {
      contentType: {
        sys: {
          id: "page",
        },
      },
    },
  },
  shortText: "Oops, this page doesn't exist.",
  longText: null,
  image: null,
  supportingImage: null,
  video: null,
  imagePosition: null,
  textColor: "light",
  type: "hero",
};

const Page404 = () => (
  <div className="design__content">
    <h4>{block404.shortText}</h4>
  </div>
);

export default Page404;
